<template>
  <div>
    <div class="container-fluid py-4 row mx-0">
      <!-- 业务介绍开始 -->
      <div
        class="
          container-fluid
          col-sm-12 col-md-12 col-lg-12 col-xl-12
          card
          mycard-scale
          my-4
        "
      >
        <div class="mx-0 my-3">
          <!-- Grid column -->
          <div>
            <h4 class="card-title font-weight-bold m-4">
              <i class="fa fa-television me-3"></i>广西桂运宝货运平台
            </h4>
            <h5 class="card-text m-4">
              提供定制化运输解决方案，为司机、货主降本增效
            </h5>

            <div class="row align-items-center my-3">
              <div class="col-md-3 col-lg-3 col-xl-2">
                <img
                  class="
                    img-thumbnail img-advantage
                    shadow
                    img_cannot_download
                    mx-auto
                  "
                  src="@/assets/images/check_online2.png"
                  alt=""
                />
              </div>
              <div class="col-md-3 col-lg-3 col-xl-2">
                <h4 class="font-weight-bold">实时查看运单更放心</h4>
                <h6>货主通过平台发单，司机接单，可随时在线查看运单进度</h6>
              </div>
              <div class="col-md-3 col-lg-3 col-xl-2">
                <img
                  class="
                    img-thumbnail
                    shadow
                    img-advantage img_cannot_download
                    mx-auto
                  "
                  src="@/assets/images/safe2.png"
                  alt=""
                />
              </div>

              <div class="col-md-3 col-lg-3 col-xl-2">
                <h4 class="font-weight-bold">货物安全更放心</h4>
                <h6>通过桂运宝货运平台购买货物险，为货物保驾护航</h6>
              </div>
              <div class="col-md-3 col-lg-3 col-xl-2">
                <img
                  class="
                    img-thumbnail
                    shadow
                    img-advantage img_cannot_download
                    mx-auto
                  "
                  src="@/assets/images/pay.png"
                  alt=""
                />
              </div>
              <div class="col-md-3 col-lg-3 col-xl-2">
                <h4 class="font-weight-bold">运费支付不重复</h4>
                <h6>通过桂运宝货运平台支付运费，应付台账更清晰</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid column -->
      <!-- Grid column -->
      <div
        class="
          container-fluid
          my-4
          card
          col-sm-12 col-md-12 col-lg-12 col-xl-12
          mycard-scale
        "
      >
        <div class="row">
          <h4 class="mt-4">部分车队货车展示</h4>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/truckspics_s.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                <h6>广西至贵州车队</h6>
                <h6>以及顺路车、返程车</h6>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/9.6高栏车.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                <h6>广西至广东车队</h6>
                <h6>以及顺路车、返程车</h6>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/9.6.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-end text-primary">
                <h6>广西至湖南车队</h6>
                <h6>以及顺路车、返程车</h6>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/6.8.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                <h6>6.8米省内车队</h6>
                <h6>以及顺路车、返程车</h6>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/自卸车.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-end text-primary">
                <h6>广西省内自卸车队</h6>
                <h6>以及顺路车、返程车</h6>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/车队6.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                <h6>广西至云南车队</h6>
                <h6>以及顺路车、返程车</h6>
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/大件运输.jpeg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                大件运输
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/17mi.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                17米平板
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/13米高栏.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                13米高栏
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/13米侧翻.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                13米侧翻
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/9.6s.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                9.6米平板
              </figcaption>
            </figure>
          </div>
          <div class="col-md-4 mt-3 col-lg-2">
            <figure class="figure">
              <img
                src="@/assets/images/7.8s.jpg"
                class="
                  figure-img
                  img-fluid
                  rounded
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                alt="..."
              />
              <figcaption class="figure-caption text-center text-primary">
                9.6米自卸
              </figcaption>
            </figure>
          </div>

          <!-- 团队介绍 -->
          <h3 class="card-title font-weight-bold my-3">
            <i class="fa fa-server me-3"></i>团队与运力资源
          </h3>
          <div class="row align-items-center my-3">
            <div class="col-md-3 col-lg-3 col-xl-2">
              <img
                class="
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                src="@/assets/images/team.png"
                alt=""
              />
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
              <h6 class="card-text">
                有顶尖的技术团队、客服团队、运营团队、营销团队、管理团队
              </h6>
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
              <img
                class="
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                src="@/assets/images/season.png"
                alt=""
              />
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
              <h6>团队在行业内运行多年，对当地货源与季节性运价非常熟悉</h6>
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
              <img
                class="
                  img-thumbnail
                  shadow
                  img-advantage img_cannot_download
                  mx-auto
                "
                src="@/assets/images/map.jpg"
                alt=""
              />
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
              <h6>
                有专业庞大的自卸车运力，以及广东、湖南、贵州、云南、四川、河南、浙江等省长期合作的专线车队，运力资源丰富
              </h6>
            </div>
          </div>
        </div>
      </div>

      <!-- Grid column -->

      <!-- Grid column -->
      <div
        class="
          container-fluid
          card
          col-sm-12 col-md-12 col-lg-12 col-xl-12
          mycard-scale
          my-4
        "
      >
        <h3 class="card-title font-weight-bold my-3">
          <i class="fa fa-handshake-o me-3"></i>行业认可
        </h3>
        <div class="row align-items-center my-3">
          <div class="col-md-3 col-lg-3 col-xl-2">
            <img
              class="
                img-thumbnail
                shadow
                img-advantage img_cannot_download
                mx-auto
              "
              src="@/assets/images/verify.png"
              alt=""
            />
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2">
            <h6>
              公司系统是经省交通厅线上服务能力认定过的货运平台公司之一
            </h6>
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2">
            <img
              class="
                img-thumbnail
                shadow
                img-advantage img_cannot_download
                mx-auto
              "
              src="@/assets/images/verify2.png"
              alt=""
            />
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2">
            <h6>
              系统功能都是按照要求定制开发，所有证件齐全、已接入省级监测系统
            </h6>
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2">
            <img
              class="
                img-thumbnail
                shadow
                img-advantage img_cannot_download
                mx-auto
              "
              src="@/assets/images/system-net-8.png"
              alt=""
            />
          </div>
          <div class="col-md-3 col-lg-3 col-xl-2">
            <h6>通过系统承接业务，促进行业降本增效</h6>
          </div>
        </div>
      </div>
      <!-- Grid column -->
    </div>
  </div>
  <!-- 业务介绍结束 -->
</template>

<script>
// @ is an alias to /src

export default {
  name: "myadvantage",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
