<template>
  <div id="app">
    <div class="row my-0 py-0" ref="top-div-nav">
      <!-- nav开始 -->
      <nav
        class="
          navbar
          sticky-top
          navbar-expand-lg navbar-dark
          bg-primary
          px-0
          mx-0
          my-0
        "
      >
        <div class="container" id="top-div">
          <a class="navbar-brand font-weight-bold" href="#">
            <h4>
              <img
                src="@/assets/images/logo.svg"
                width="60"
                height="40"
                class="
                  d-inline-block
                  align-items-end
                  mb-2
                  mt-2
                  mx-0
                  img_cannot_download
                "
                alt=""
              />
              桂运宝
            </h4>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/"
                  >主页</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link link-success" to="/companynews">
                  公司新闻
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about">关于我们</router-link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle link-success"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  联系我们
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      class="dropdown-item link-success"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <font-awesome-icon
                        class="me-3"
                        :icon="['fab', 'weixin']"
                      />关注微信公众号
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item link-success"
                      role="button"
                      href="mailto:guiyunbaokeji@163.com"
                      ><font-awesome-icon class="me-3" icon="envelope" />
                      电子邮箱guiyunbaokeji@163.com</a
                    >
                  </li>
                  <li>
                    <a
                      role="button"
                      class="dropdown-item link-success"
                      href="tel:07722991568"
                      ><font-awesome-icon
                        class="me-3"
                        icon="phone"
                      />客服热线0772-2991568</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      role="button"
                      href="tel:18178808555"
                      class="dropdown-item link-success"
                    >
                      <font-awesome-icon
                        class="me-3"
                        icon="mobile"
                      />业务电话18178808555
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="mb-0 list-unstyled">
              <li>
                <a href="tel:07722991568" class="d-flex text-white"
                  ><h6>
                    <font-awesome-icon
                      class="me-3"
                      icon="phone-volume"
                    />客服热线0772-2991568
                  </h6></a
                >
              </li>
              <li>
                <a href="tel:18178808555" class="d-flex text-white"
                  ><h6>
                    <font-awesome-icon
                      class="me-3"
                      icon="mobile"
                    />业务电话18178808555
                  </h6></a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <!-- nav结束 -->
    <!-- 内容部分  -->
    <div class="row">
      <div class="container-fluid bg-success px-0 mx-0">
        <router-view />
      </div>

      <!-- Footer开始 -->
      <div class="container-fluid bg-my-gradual my-0">
        <fee-check v-if="$route.name === 'Home'" />
        <MyAdvantage v-if="$route.name === 'Home'" />
        <!-- 合作方介绍开始 -->
        <div class="container">
          <h4 class="d-flex justify-content-between py-5 text-mydarkblue">
            部分合作方
          </h4>
          <div class="row text-center text-md-left mt-3 pb-3">
            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <a href=""
                ><img
                  class="img-thumnail mb-3 corpartner-img rounded"
                  src="./assets/images/hengda.png"
                  alt=""
                />
              </a>
              <p>
                <a>广西恒达电机科技有限公司</a>
              </p>

              <p>广西百邕贸易有限公司</p>

              <p>柳州市利辰机电设备有限公司</p>
            </div>
            <!-- Grid column -->
            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <a href="#!"
                ><img
                  class="img-thumnail mb-3 corpartner-img rounded link-success"
                  src="./assets/images/zhangyis.png"
                  alt=""
                />
              </a>
              <p>柳州易账会计服务有限公司</p>
              <p>平南县宝来钙业有限公司</p>
              <p>柳州龙昌贸易有限公司</p>
            </div>
            <!-- Grid column -->
            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <a
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
                ><img
                  class="img-thumnail mb-3 corpartner-img rounded link-success"
                  src="./assets/images/anan.jpg"
                  alt=""
                />
              </a>
              <p>
                <!-- <a
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  aria-controls="offcanvasBottom"
                > -->
                <a> 柳州市桉桉企业管理咨询有限公司 </a>
              </p>

              <p>柳州市环波建材有限公司</p>
              <p>广西海螺水泥厂</p>
            </div>
            <!-- Grid column -->
          </div>
          <hr class="w-100 clearfix d-md-none" />
          <!-- Grid column -->
        </div>
        <!-- 合作方介绍结束 -->
        <footer class="page-footer font-small pt-4">
          <!-- Footer Links -->
          <div class="container text-center text-md-left">
            <!-- Footer links -->
            <div class="row text-center text-md-left mt-3 pb-3">
              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 class="mb-4 font-weight-bold">扫描关注微信公众号</h6>
                <img
                  src="./assets/images/qrcode_s.jpg"
                  class="img-thumbnail"
                  alt="微信公众号"
                  width="120"
                />
              </div>
              <!-- Grid column -->

              <hr class="w-100 clearfix my-3 d-md-none" />

              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 class="text-uppercase mb-4 font-weight-bold">相关链接</h6>
                <a
                  class="link-success"
                  href="https://next.guiyunbao.cn/#login"
                >
                  <p>
                    <font-awesome-icon
                      class="me-3"
                      icon="truck"
                    />桂运宝货运平台
                  </p>
                </a>

                <a>
                  <p
                    class="link-success"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <font-awesome-icon
                      class="me-3"
                      :icon="['fab', 'weixin']"
                    />关注微信公众号
                  </p>
                </a>
                <p>
                  <router-link
                    class="nav-link link-success my-3"
                    to="/truckage"
                  >
                    <font-awesome-icon
                      class="me-3"
                      :icon="['fab', 'searchengin']"
                    />专线及运费查询
                  </router-link>
                </p>
              </div>

              <!-- Grid column -->
              <hr class="w-100 clearfix d-md-none" />

              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 class="text-uppercase mb-4 font-weight-bold">联系我们</h6>
                <p>
                  <font-awesome-icon class="me-3" icon="map-marked" />
                  柳州市广场路10号柳州地王国际财富中心2栋38-9
                </p>
                <!-- <p><i class="fa fa-print me-3"></i> 0772-2991568</p> -->
                <p>
                  <a class="link-success" href="tel:07722991568"
                    ><font-awesome-icon class="me-3" icon="phone" />咨询热线
                    0772-2991568</a
                  >
                </p>
                <p>
                  <a class="link-success" href="mailto:guiyunbaokeji@163.com"
                    ><font-awesome-icon class="me-3" icon="envelope" />
                    guiyunbaokeji@163.com</a
                  >
                </p>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Footer links -->

            <hr />

            <!-- Grid row -->
            <div class="row d-flex align-items-center">
              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <!--Copyright-->
                <p class="text-center text-md-left">
                  © 2021:
                  <a class="link-success" href="https://guiyunbao.cn/">
                    <strong>广西桂运宝科技有限公司</strong>
                  </a>
                </p>
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <div style="width: 300px; margin: 0 auto; padding: 20px 0">
                  <a
                    target="_blank"
                    class="link-success"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45020502000195"
                    style="
                      display: inline-block;
                      text-decoration: none;
                      height: 20px;
                      line-height: 20px;
                    "
                    ><img src="./assets/images/beian.png" style="float: left" />
                    <p
                      style="
                        float: left;
                        height: 20px;
                        line-height: 20px;
                        margin: 0px 0px 0px 5px;
                      "
                    >
                      桂公网安备 45020502000195号
                    </p></a
                  >
                  <p class="text-center text-md-left pt-0">
                    备案号：
                    <a
                      class="link-success"
                      href="https://beian.miit.gov.cn/"
                      target="_blank"
                      ><strong>桂ICP备20000740号-2</strong></a
                    >
                  </p>
                </div>
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <!-- Social buttons -->
                <div class="text-center text-md-right">
                  <ul class="list-unstyled list-inline">
                    <li class="list-inline-item">
                      <a
                        class="
                          btn-floating btn-sm
                          rgba-white-slight
                          mx-1
                          link-success
                        "
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <font-awesome-icon
                          class="me-3"
                          :icon="['fab', 'weixin']"
                        />
                      </a>
                    </li>
                    <li
                      class="list-inline-item"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <a
                        class="
                          btn-floating btn-sm
                          rgba-white-slight
                          link-success
                          mx-1
                        "
                      >
                        <font-awesome-icon class="me-3" :icon="['fab', 'qq']" />
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="
                          btn-floating btn-sm
                          rgba-white-slight
                          mx-1
                          link-success
                        "
                        href="tel:07722991568"
                      >
                        <font-awesome-icon class="me-3" icon="phone" />
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="
                          btn-floating btn-sm
                          rgba-white-slight
                          mx-1
                          link-success
                        "
                        href="mailto:guiyunbaokeji@163.com"
                      >
                        <font-awesome-icon class="me-3" icon="envelope" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
          <!-- Footer Links -->
        </footer>
      </div>
      <!-- Footer结束 -->
      <!-- 侧边快捷操作开始 -->
      <div id="mySidenav" class="sidenav">
        <router-link
          to="/"
          id="myhome"
          class="nav-link shadow"
          aria-current="page"
          ><font-awesome-icon icon="home" />
        </router-link>
        <a
          href="#top-div"
          id="toTop"
          class="nav-link shadow"
          aria-current="page"
        >
          <font-awesome-icon class="me-3" icon="arrow-alt-circle-up" />
        </a>
        <a
          href="tel:18178808555"
          id="toCall"
          class="nav-link shadow"
          aria-current="page"
        >
          <font-awesome-icon class="me-3" icon="phone-volume" />
        </a>
      </div>

      <!-- 侧边快捷操作结束 -->
    </div>
    <!-- 关注微信公众号开始 Modal 默认不显示 -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              打开微信扫码关注
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <img class="d-block mx-auto" src="./assets/images/qrcode_s.jpg" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              关闭
            </button>
            <button type="button" class="btn btn-primary">确定</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 关注微信公众号结束 Modal 默认不显示 -->
    <!-- 显示桉桉内容 -->
    <!-- <div
      class="offcanvas offcanvas-bottom"
      tabindex="-1"
      id="offcanvasBottom"
      aria-labelledby="offcanvasBottomLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasBottomLabel">桉桉企管</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body small card bg-light">
        <img class="img-fluid" src="./assets/images/anan3.png" />
      </div>
    </div> -->
  </div>
</template>

<script>
import FeeCheck from "./components/feeCheck.vue";
import MyAdvantage from "./components/myadvantage.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarked,
  faPhone,
  faEnvelope,
  faHome,
  faArrowAltCircleUp,
  faPhoneVolume,
  faTruck,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWeixin,
  faQq,
  faSearchengin,
} from "@fortawesome/free-brands-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
library.add(
  faMapMarked,
  faPhone,
  faEnvelope,
  faWeixin,
  faHome,
  faQq,
  faSearchengin,
  faArrowAltCircleUp,
  faPhoneVolume,
  faTruck,
  faMobile
);

export default {
  data: function () {
    return {
      base_url: this.$backend_url,
    };
  },
  components: { FeeCheck, MyAdvantage },
  methods: {},
};
</script>

<style>
.bg-my-gradual {
  background: radial-gradient(
      closest-side at 50% 4%,
      rgba(255, 255, 255, 0.6) 20%,
      rgba(33, 158, 188, 0.1) 80%
    ),
    linear-gradient(rgb(0, 147, 190), white 80%);
}
.corpartner-img {
  width: 80px;
  height: 80px;
}
</style>
